import { Box, Link, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../../SupportingFiles/colors";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

function MLoginPopup({
  open,
  setOpen,
  showEnterMobileSection,
  setShowEnterMobileSection,
  loginFields,
  setLoginFields,
  fieldsUpdateState,
  handleShowMessage,
  showMessage,
  resendOtp,
  startTime,
  setStartTime,
}: {
  open: boolean;
  setOpen: any;
  showEnterMobileSection: boolean;
  startTime: boolean;
  setShowEnterMobileSection: any;
  loginFields: any;
  setLoginFields: any;
  fieldsUpdateState: Function;
  handleShowMessage: Function;
  showMessage: Function;
  setStartTime: Function;
  resendOtp: Function;
}) {
  const [loginStep, setLoginStep] = useState<"1" | "2">("1");

  const [formData, setFormData] = useState<{
    mobile: { value: string; warning: boolean };
    otp: { value: string; warning: boolean };
  }>({
    mobile: { value: "", warning: false },
    otp: { value: "", warning: false },
  });

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, []);

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "#ffffff",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "84px",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          {/* topnav */}
          <Box
            sx={{
              // backgroundColor: "rgba(255, 243, 244, 1)",
              height: "60px",
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "9999",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              className="row"
              spacing={3}
              alignItems="center"
              width="100%"
            >
              <Grid xs={12} paddingLeft={"40px"} paddingRight={"40px"}>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setLoginStep("1");
                  }}
                  sx={{
                    position: "absolute",
                    right: "12px",
                    top: "19px",
                    maxWidth: "22px",
                    minWidth: "22px",
                    maxHeight: "22px",
                    color: COLORS.white,
                    backgroundColor: COLORS.primary,
                    borderRadius: "60px",
                    transition: "all ease-in-out 0.2s",
                    "&:hover": {
                      backgroundColor: COLORS.darkGrey,
                      transform: "rotate(-90deg)",
                    },
                  }}
                >
                  <CloseIcon style={{ height: "18px", width: "18px" }} />
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* contentsec */}
          <Box>
            {showEnterMobileSection ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                  <img src="./images/login_popup_img1.svg" alt="" />
                </Grid>
                <Grid xs={12} textAlign={"center"}>
                  <h4 style={{ fontSize: "20px", marginBottom: "8px" }}>
                    Customer Login
                  </h4>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "24px",
                      fontSize: "12px",
                      lineHeight: "18px",
                    }}
                  >
                    Hey, Enter your details to get sign in to your account
                  </p>
                </Grid>
                <Grid xs={8}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter mobile number"}
                    value={loginFields.enterMobile.value}
                    // attrName={"mobile"}
                    attrName={[
                      "enterMobile",
                      "value",
                      loginFields,
                      setLoginFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={loginFields.enterMobile.warning}
                    error_message={
                      isEmpty(loginFields.enterMobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                    max_length={10}
                    validation_type="NUMBER"
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="Send OTP"
                    onClick={() => {
                      handleShowMessage();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                  <img src="./images/login_popup_img2.svg" alt="" />
                </Grid>
                <Grid xs={12} textAlign={"center"}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}
                  >
                    OTP sent successfully on{" "}
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      +91-{loginFields.enterMobile.value}
                    </span>
                    <Link
                      sx={{
                        background: "rgba(242, 242, 242, 1)",
                        fontSize: "12px",
                        padding: "2px 10px",
                        borderRadius: "40px",
                        color: "rgba(51, 51, 51, 1)",
                        fontWeight: 500,
                        marginLeft: "12px",
                        display: "inline-block",
                        lineHeight: "18px",
                      }}
                      onClick={() => {
                        setShowEnterMobileSection(true);
                      }}
                    >
                      Edit
                    </Link>
                  </p>
                </Grid>
                <Grid xs={8}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter OTP"}
                    validation_type="NUMBER"
                    value={loginFields.enterOtp.value}
                    attrName={[
                      "enterOtp",
                      "value",
                      loginFields,
                      setLoginFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={loginFields.enterOtp.warning}
                    error_message={
                      isEmpty(loginFields.enterOtp.value)
                        ? "Enter OTP"
                        : "Enter Valid OTP"
                    }
                    max_length={4}
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"16px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="Verify OTP"
                    onClick={() => {
                      showMessage();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid xs={12}>
                  <p style={{ textAlign: "center" }}>
                    Didn’t receive?{" "}
                    <Link
                      sx={{ color: COLORS.primary, fontWeight: 600 }}
                      onClick={() => {
                        setLoginFields({
                          ...loginFields,
                          enterOtp: { value: "", warning: false },
                        });
                        resendOtp();
                        setStartTime(true);
                      }}
                    >
                      Resend OTP
                    </Link>
                  </p>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MLoginPopup;
