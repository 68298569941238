// import { URL_CONSTANTS } from "../../URLCollection/URL";
import { GetAPI, PostAPI } from "../../Axios/AxiosInstanceToken";
import { URL_CONSTANTS } from "../../Routing/Path/CarurlConstant";
// import { AddTermDTO } from "../../DTO/TermDTO/AddTermFormDTO";

// const updateProfile = (onSuccess: Function, onError: Function, dto: any) => {
//   PostAPI.call(
//     URL_CONSTANTS.ACCOUNT_PROFILE,
//     dto,
//     (res: any) => onSuccess(res.data),
//     onError
//   );
// };
// const getProfile = (onSuccess: Function, onError: Function, dto: any) => {
//   PostAPI.call(
//     URL_CONSTANTS.GET_PROFILE,
//     dto,
//     (res: any) => onSuccess(res.data),
//     onError
//   );
// };

const accountLogin = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    URL_CONSTANTS.ACCOUNT_VERIFY_MOBILE,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const accountLoginResendOtp = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.ACCOUNT_VERIFY_RESEND_OTP,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const accountLoginVerifyOtp = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.ACCOUNT_VERIFY_OTP,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const ACCOUNT_SERVICES = {
  // updateProfile,
  accountLogin,
  accountLoginResendOtp,
  accountLoginVerifyOtp,
  // getProfile,
};
