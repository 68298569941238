import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./NavBar.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import CustomButton from "../../../InputFields/CustomButton/CustomButton";
import LoginPopup from "./LoginPopup/LoginPopup";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/Store/hooks";
import LoginContainer from "../../../../Container/LoginContainer/LoginContainer";
import { UserProfileSlice } from "../../../../Store/Reducer/Account/UserProfileSlice";
import { FRONTEND_DOMAIN } from "../../../../Routing/CommonURLs/Domain";
import { MY_ACCOUNT_ROUTES } from "../../../../Routing/Path/MyAccountRoutes";
import { SESSION_CONSTANTS } from "../../../../Services/Constants/SessionConstants";

function NavBar() {
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClose2 = () => {
    setAnchorE2(null);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMenuOpen2 = Boolean(anchorE2);
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };

  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);
  return (
    <Box className="navbar py-3">
      {/* <LoginPopup
        open={openLoginPopup}
        handleClose={() => setOpenLoginPopup(false)}
      /> */}
      <LoginContainer open={openLoginPopup} setOpen={setOpenLoginPopup} />
      <Grid container className="row" columnSpacing={3} alignItems="center">
        <Grid xs={3}>
          <img
            src="./images/muthoot-logo.svg"
            height="62px"
            alt="Muthoot Insurance"
            onClick={() => {
              window.location.replace("/");
            }}
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid xs={9}>
          <Box
            marginBottom="8px"
            display="flex"
            justifyContent="flex-end"
            gap="24px"
            alignItems="center"
          >
            {/* <Link
              sx={{
                display: "flex",
                alignItems: "center",
                color: COLORS.black,
                fontWeight: 500,
              }}
            >
              <img
                src="./images/nav_feedback_icon.svg"
                alt=""
                style={{ width: "26px", marginRight: "4px" }}
              />
              Feedback
            </Link> */}
            <p style={{ lineHeight: "21px", fontWeight: 500 }}>
              Toll Free Number: 8590223204
            </p>
          </Box>
          <Box>
            <ul className="nav-list">
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.ABOUT_US);
                  }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Button
                  className="nav-link product-link"
                  id="basic-link"
                  aria-controls={isMenuOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={isMenuOpen ? "true" : undefined}
                  onClick={handleMenuClick}
                  // onMouseEnter={handleMenuClick}
                  disableRipple
                  sx={{
                    padding: 0,
                    color: COLORS.black,
                    fontWeight: 400,
                    textTransform: "capitalize",
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontFamily: "Poppins",
                    display: "inline-flex",
                    alignItems: "center",
                    "&:after": {
                      content: "''",
                      position: "absolute",
                      bottom: "-5px",
                      left: "0",
                      height: "2px",
                      borderRadius: "30px",
                      width: 0,
                      background: COLORS.primary,
                      transition: "width 0.3s ease",
                    },
                    "&:hover": {
                      background: "none",
                      color: COLORS.primary,
                      "&:after": {
                        width: "97%",
                      },
                    },
                  }}
                >
                  Insurance Products{" "}
                  <KeyboardArrowDownRoundedIcon sx={{ marginLeft: "4px" }} />
                </Button>
                <Menu
                  className="popoverMenu"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-link",
                    sx: {
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontFamily: "jioType",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                  >
                    Health Insurance
                  </MenuItem>
                  <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                    Car Insurance
                  </MenuItem>
                  <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                    Two-Wheeler Insurance
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}
                  >
                    Term Insurance
                  </MenuItem>
                  {/* <MenuItem>Non-Term Insurance</MenuItem> */}
                </Menu>
              </li>
              {/* <li>
                <Link>Renewal</Link>
              </li> */}
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.CLAIM);
                  }}
                >
                  Claim
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.CAREER);
                  }}
                >
                  Careers
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.CONTACT);
                  }}
                >
                  Contact
                </Link>
              </li>
              {USER_DATA.mobile.value ? (
                <li style={{ width: "106px" }} className="primaryBtn medium">
                  <Button
                    className="primaryBtn medium"
                    id="basic-link"
                    aria-controls={isMenuOpen2 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={isMenuOpen2 ? "true" : undefined}
                    onClick={handleMenuClick2}
                    // onMouseEnter={handleMenuClick}
                    disableRipple
                  >
                    Hi,User
                  </Button>
                  <Menu
                    className="popoverMenu"
                    id="basic-menu"
                    anchorEl={anchorE2}
                    open={isMenuOpen2}
                    onClose={handleMenuClose2}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                      },
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-link",
                    }}
                  >
                    <MenuItem
                      // onClick={() => {
                      //   // handleClose();
                      //   window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                      // }}

                      onClick={() => {
                        const encodedMobile = btoa(USER_DATA.mobile.value);
                        sessionStorage.setItem(
                          SESSION_CONSTANTS.USER_MOBILE_NO,
                          encodedMobile
                        );
                        window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${encodedMobile}`;
                      }}
                    >
                      Dashboard
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </li>
              ) : (
                <CustomButton
                  className="primaryBtn medium"
                  text="Login"
                  fullWidth
                  variant="contained"
                  onClick={() => setOpenLoginPopup(true)}
                />
              )}
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NavBar;
