import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MNavbar.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MLoginPopup from "./MLoginPopup/MLoginPopup";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import LoginContainer from "../../../../Container/LoginContainer/LoginContainer";
import { UserProfileSlice } from "../../../../Store/Reducer/Account/UserProfileSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/Store/hooks";
import { FRONTEND_DOMAIN } from "../../../../Routing/CommonURLs/Domain";
import { MY_ACCOUNT_ROUTES } from "../../../../Routing/Path/MyAccountRoutes";
import { SESSION_CONSTANTS } from "../../../../Services/Constants/SessionConstants";

function MNavBar() {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openLoginPopup, setOpenLoginPopup] = React.useState(false);

  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenMenu(open);
    };

  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };

  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);

  return (
    <Box className="mnavbar">
      <Grid container className="row" columnSpacing={3} alignItems={"center"}>
        <Grid xs={12}>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            gap={"12px"}
            alignItems={"center"}
            padding={"4px 0px"}
          >
            {/* <Link
              sx={{
                display: "flex",
                alignItems: "center",
                color: COLORS.black,
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400,
              }}
            >
              <img
                src="./images/nav_feedback_icon.svg"
                alt=""e
                style={{ width: "24px", marginRight: "4px" }}
              />
              Feedback
            </Link> */}
            <p
              style={{ fontSize: "12px", lineHeight: "18px", fontWeight: 400 }}
            >
              Toll Free Number: 8590223204
            </p>
          </Box>
        </Grid>
        <Grid xs={6}>
          <img
            src="./images/muthoot-logo.svg"
            height="44px"
            alt="Muthoot Insurance"
            onClick={() => {
              window.location.replace("/");
            }}
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid xs={6} textAlign={"right"}>
          <Link className="hamburger-menu" onClick={toggleDrawer("left", true)}>
            <img
              src="./images/menu_icon.svg"
              alt=""
              height={"25px "}
              width={"25px"}
            />
          </Link>
        </Grid>
      </Grid>

      {/* sidemenu */}
      <SwipeableDrawer
        anchor="left"
        open={openMenu}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <Box className="menuWrapper">
          {USER_DATA.mobile.value ? (
            <Box className="top-sec">
              <Grid
                container
                className="row"
                columnSpacing={3}
                alignItems={"center"}
              >
                <Grid xs={12} marginBottom={"8px"}>
                  <Box className="user_box">
                    <img src="./images/user-icon.svg" alt="" />
                    <p>Hi User</p>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <p className="menu-heading mb-2">User Account</p>
                  <Link
                    className="dash-link mb-2"
                    // onClick={() => {
                    //   // handleClose();
                    //   window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                    // }}

                    onClick={() => {
                      const encodedMobile = btoa(USER_DATA.mobile.value);
                      sessionStorage.setItem(
                        SESSION_CONSTANTS.USER_MOBILE_NO,
                        encodedMobile
                      );
                      window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${encodedMobile}`;
                    }}
                  >
                    <img src="./images/menu_dashboard_icon.svg" alt="" />
                    <span>Dashboard</span>
                  </Link>
                  <Link className="dash-link" onClick={handleLogout}>
                    <img src="./images/menu_logout_icon.svg" alt="" />
                    <span>Logout</span>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box className="top-sec">
              <Grid
                container
                className="row"
                columnSpacing={3}
                alignItems={"center"}
              >
                <Grid xs={12}>
                  <Link
                    className="dash-link-login"
                    onClick={() => {
                      setOpenLoginPopup(true);
                      setOpenMenu(false);
                    }}
                  >
                    <img src="./images/menu_logout_icon.svg" alt="" />
                    <span>Login</span>
                  </Link>
                  {/* <MLoginPopup
                  open={openLoginPopup}
                  setOpen={setOpenLoginPopup}
                /> */}
                </Grid>
              </Grid>
            </Box>
          )}

          <Box className="menu-section">
            <p className="menu-heading">Services</p>
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CONTACT)}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CAREER)}
                >
                  Career
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CLAIM)}
                >
                  Claim
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.COMPLAINT)}
                >
                  Complaint
                </Link>
              </li>
            </ul>
          </Box>
          <Box className="menu-section pt-0">
            <p className="menu-heading">Products</p>
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                >
                  Health Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}
                >
                  Car Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}
                >
                  Two-Wheeler Insurance
                </Link>
              </li>
              {/* <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}
                >
                  Term Insurance
                </Link>
              </li> */}
            </ul>
          </Box>
        </Box>
      </SwipeableDrawer>
      <LoginContainer open={openLoginPopup} setOpen={setOpenLoginPopup} />
    </Box>
  );
}

export default MNavBar;
