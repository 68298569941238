let nodeDomain, phpDomain, frontendDomain;

if (process.env.REACT_APP_ENV === "DEV") {
  nodeDomain = "https://devnodeapi.muthootinsurancebrokers.com";
  phpDomain = "https://devapi.muthootinsurancebrokers.com";
  frontendDomain = "https://dev.muthootinsurancebrokers.com";
} else if (process.env.REACT_APP_ENV === "STG") {
  nodeDomain = "https://stgnodeapi.muthootinsurancebrokers.com";
  phpDomain = "https://stgapi.muthootinsurancebrokers.com";
  frontendDomain = "https://stg.muthootinsurancebrokers.com";
} else if (process.env.REACT_APP_ENV === "PROD") {
  nodeDomain = "https://nodeapi.muthootinsurancebrokers.com";
  phpDomain = "https://api.muthootinsurancebrokers.com";
  frontendDomain = "https://muthootinsurancebrokers.com";
} else {
  nodeDomain = "";
  phpDomain = "";
  frontendDomain = "";
}

export const NODE_DOMAIN = nodeDomain;
export const PHP_DOMAIN = phpDomain;
export const FRONTEND_DOMAIN = frontendDomain;
